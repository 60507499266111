<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="row">
        <div class="col-md-12">
          <form
            @submit.prevent="submit"
            id="logo"
            ref="addLogo"
            class="needs-validation"
            novalidate
          >
            <div class="form-group row">
              <div class="col-md-12">
                <img
                  v-if="filePreview"
                  :src="filePreview"
                  alt=""
                  class="my-2"
                  height="100"
                />

                <label for="file-upload" class="custom-file-upload form-control"
                  >Upload File *</label
                >
                <span class="text-danger d-block" v-if="errors.file">
                  {{ errors.file }}
                </span>
                <input
                  type="file"
                  ref="file"
                  id="file-upload"
                  accept="image/*"
                  placeholder="Select File *"
                  class="form-control form-control-sm"
                  @change="selectFile()"
                  required
                />
                <div class="invalid-feedback text-danger">
                  Please select a file.
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-3">
                <button type="submit" class="c-button cbg-primary">
                  Submit
                </button>
                <button
                  type="button"
                  @click="this.$refs.addLogo.reset()"
                  class="ms-2 c-button cbg-secondary"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";
import Bread_crumbs from "@/components/bread_crumbs.vue";

export default {
  components: {
    Bread_crumbs,
  },
  props:{
    logoFile: Object
  },
  mixins: [isNumber],
  data() {
    return {
      logo: {
        file: null,
      },
      filePreview: "/promotionals/logo/image.png",
      errors: [],
      loading: false,
    };
  },
  mounted() {
    if(this.logoFile != "{}"){
      this.filePreview = this.logoFile.path;
    }
  },
  methods: {
    submit() {
      var form = document.getElementById("logo");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw(this.logo);
        this.$store
          .dispatch("promotional/storeLogo", { data })
          .then((res) => {
            this.loading = false;
            this.$toast.show("Successfuly added.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.$refs.addLogo.reset();
            this.errors = [];
          })
          .catch((e) => {
            this.loading = false;
            this.errors = e.error;
            this.$toast.show(JSON.stringify(e.error), {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
    selectFile() {
      let file = this.$refs.file.files[0];
      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png"
      ) {
        if (
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png"
        ) {
          this.attachmentType = "Image";
        }
        let reader = new FileReader();
        reader.onloadend = () => {
          this.logo.file = reader.result;
          this.filePreview = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        file.value = null;
        this.$toast.show("File format not supported. JPG/JPEG/PNG", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
  },
};
</script>
  