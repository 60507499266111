<template>
  <router-link to="/">
    <img
      class="img-fluid for-light"
      src="@/assets/vhw.png"
      alt=""
      style="width: 160px"
    />
    <h5 style="color: #000;">LMS Officials</h5>
  </router-link>
</template>
<script>
export default {
  name: "Logo",
};
</script>
