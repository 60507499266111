<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="row">
        <div class="col-md-12">
          <form
            @submit.prevent="submit"
            id="hiw"
            ref="addHowItWorks"
            class="needs-validation"
            novalidate
          >
            <div class="form-group row">
              <div class="col-md-12 mb-3">
                <span class="text-danger d-block" v-if="errors.title">
                    {{ errors.title }}
                  </span>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  placeholder="Title *"
                  required
                  v-model="hiw.title"
                />
                <div class="invalid-feedback text-danger">
                  Please provide a title.
                </div>
              </div>
              <div class="col-md-12 mb-3">
                <span class="text-danger d-block" v-if="errors.desc">
                  {{ errors.desc }}
                </span>
                <textarea
                  name=""
                  id=""
                  rows="7"
                  class="text-area"
                  placeholder="Type description.... *"
                  v-model="hiw.desc"
                  required
                ></textarea>
                <div class="invalid-feedback text-danger">
                  Please provide a description.
                </div>
              </div>
              <div class="col-md-12">
                <img
                  v-if="imagePreview"
                  :src="imagePreview"
                  alt=""
                  class="my-2 w-100"
                  height="150"
                />

                <label
                  for="file-upload-video"
                  class="custom-file-upload form-control"
                  >Upload Video File *</label
                >
                <input
                  type="file"
                  ref="videoFile"
                  id="file-upload-video"
                  accept="video/mp4"
                  placeholder="Select File *"
                  class="form-control form-control-sm"
                  @change="selectFile()"
                />
                <video
                      width="100%"
                      class="mt-2"
                      height="300"
                      controls
                      v-if="videoPreview"
                      :src="videoPreview"
                    >
                      <!-- <source :src="videoPreview" type="video/mp4" />
                      Your browser does not support the video tag. -->
                    </video>
                    <div v-if="uploadProgress > 0">
                      <progress :value="uploadProgress" max="100">
                        {{ uploadProgress }}%
                      </progress>
                      <p>{{ uploadProgress }}%</p>
                    </div>
    
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-3">
                <button type="submit" class="c-button cbg-primary">
                  Submit
                </button>
                <button
                  type="button"
                  @click="this.$refs.addHowItWorks.reset()"
                  class="ms-2 c-button cbg-secondary"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";
import Bread_crumbs from "@/components/bread_crumbs.vue";

export default {
  components: {
    Bread_crumbs,
  },
  props: {
    hiwData: Object,
  },
  mixins: [isNumber],
  data() {
    return {
      hiw: {
        title: null,
        desc: null,
        video: null,
      },
      imagePreview: "/promotionals/how-it-works/image.png",
      videoPreview: null,
      uploadProgress: 0,
      errors: [],
      loading: false,
    };
  },
  mounted() {
    if(this.hiwData != "{}"){
      this.hiw = this.hiwData;
      this.videoPreview = this.hiwData.file.path;
    }
  },
  methods: {
    submit() {
      var form = document.getElementById("hiw");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw(this.hiw);
        this.$store
          .dispatch("promotional/storeHowItWorks", { data, onUploadProgress: this.onUploadProgress })
          .then((res) => {
            this.loading = false;
            this.$toast.show("Successfuly added.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.uploadProgress=0;
            this.$refs.addHowItWorks.reset();
            this.errors = [];
          })
          .catch((e) => {
            this.loading = false;
            this.errors = e.error;
            this.$toast.show(JSON.stringify(e.error), {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
    onUploadProgress(progressEvent) {
      this.uploadProgress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
    },
    selectFile() {
      let file = this.$refs.videoFile.files[0];

      if (file.type == "video/mp4") {
        if (file.type == "video/mp4") {
          this.attachmentType = "Video";
        }
        let reader = new FileReader();
        this.videoPreview = URL.createObjectURL(file);
        reader.onloadend = () => {
          this.hiw.video = reader.result;
          // this.videoPreview = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
        file.value = null;
        this.$toast.show("File format not supported. MP4 Only", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
  },
};
</script>
  