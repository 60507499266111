import { createWebHistory, createRouter } from "vue-router";
import Body from "../components/body";
import Dashboard from "@/views/dashboard/default";
import Login from "@/auth/login";
import UnAuthorized from "@/auth/unauthorize.vue";
import VerfiyEmail from "@/auth/verify-email.vue";
import ResetPassword from "@/auth/reset_password.vue";
import Promotional from "@/views/promotional/index.vue";
import IndexUserRequest from "@/views/user-requests/index.vue";

// roles
import RoleIndex from "@/views/role/index";
import AddRole from "@/views/role/add";
import EditRole from "@/views/role/edit";

// app user routes
import AppUserRoutes from './app-user';

// family member routes
import FamilyMemberRoutes from './app-user/family-members';

// medical history routes
import MedicalHistoryRoutes from './app-user/medical-histories';

// prescription routes
import PrescriptionRoutes from './app-user/prescriptions';

// appointments routes
import AppointmentRoutes from './app-user/appointments';

// qualification routes
import QualificationRoutes from './qualifications';

// specialization routes
import SpecializationRoutes from './specializations';

// doctor routes
import DoctorRoutes from './doctors';

// chw routes
import CHWRoutes from './chw';

// campaign routes
import CampaignRoutes from './campaigns';

// dynamic-forms routes
import DynamicFormRoutes from './dynamic-forms';

// tasks
import TaskRoutes from './tasks';

// health-literacy-content
import healthLiteracyContentRoutes from './health-literacy-content';

// categories
import categoryRoutes from './categories';

// interests
import interestRoutes from './interests';
import userMessaggesRoutes from './user-messages';

// facility-centers
import facilityCenterRoutes from './facility-centers'

// feedbacks
import feedbackRoutes from './feedbacks';

// supports
import supportRoutes from './supports';

// pages
import PagesRoutes from './pages';

// faqs
import faqRoutes from './faqs';

// faqs
import NotificationRoutes from './notifications';

// districts
import IndexDistrict from "@/views/location/district/index";
import AddDistrict from "@/views/location/district/add";
import EditDistrict from "@/views/location/district/edit";

// tehsil
import IndexTehsil from "@/views/location/tehsil/index";
import AddTehsil from "@/views/location/tehsil/add";
import EditTehsil from "@/views/location/tehsil/edit";

// area
import IndexVC from "@/views/location/area/index";
import AddVC from "@/views/location/area/add";
import EditVC from "@/views/location/area/edit";

// admins
import AdminIndex from "@/views/admin/index";
import AddAdmin from "@/views/admin/add";
import EditAdmin from "@/views/admin/edit";
import AdminProfile from "@/views/admin/profile";
import AdminChangePassword from "@/views/admin/changePassword";
import Register from "@/auth/register.vue";
import store from '@/store';

const routes = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    component: Body,
    children: [
      {
        path: "/dashboard",
        component: Dashboard,
        meta: {
          requiresAuth: true,
          requiredPermission: 'view_sub_admin'
        }
      },
    ],
  },
  {
    path: "/promotional",
    component: Body,
    children: [
      {
        path: "/promotional",
        component: Promotional,
        meta: {
          requiresAuth: true,
          requiredPermission: 'view_sub_admin'
        }
      },
    ],
  },
  {
    path: "/authorization",
    name: "unAuthorized",
    component: UnAuthorized,
    meta: {
      public: true,
    },
  },
  {
    path: "/auth",
    children: [
      {
        path: "login",
        name: "Login 1",
        component: Login,
        meta: {
          public: true,
        },
      },
      // {
      //   path: "register",
      //   name: "Login 1",
      //   component: Register,
      //   meta: {
      //     public: true,
      //   },
      // },
      {
        path: "verifyEmail",
        component: VerfiyEmail,
        meta: {
          public: true,
        },
      },
      {
        path: "resetPassword",
        component: ResetPassword,
        meta: {
          public: true,
        },
      },
    ],
  },
  {
    path: "/admins",
    component: Body,
    children: [
      {
        path: "/admins/profile",
        component: AdminProfile,
        meta: {
          requiresAuth: true,
          requiredPermission: 'view_sub_admin'
        }
      },
      {
        path: "/admins/changePassword/:id?",
        component: AdminChangePassword,
        meta: {
          requiresAuth: true,
          requiredPermission: 'edit_sub_admin'
        }
      },
      {
        path: "/admins",
        component: AdminIndex,
        meta: {
          requiresAuth: true,
          requiredPermission: 'view_sub_admin'
        }
      },
      {
        path: "/admins/add",
        component: AddAdmin,
        meta: {
          requiresAuth: true,
          requiredPermission: 'create_sub_admin'
        }
      },
      {
        path: "/admins/edit/:id",
        component: EditAdmin,
        meta: {
          requiresAuth: true,
          requiredPermission: 'edit_sub_admin'
        }
      },
    ],
  },
  {
    path: "/roles",
    component: Body,
    children: [
      {
        path: "/roles",
        component: RoleIndex,
        meta: {
          requiresAuth: true,
          requiredPermission: 'view_role'
        }
      },
      {
        path: "/roles/add",
        component: AddRole,
        meta: {
          requiresAuth: true,
          requiredPermission: 'create_role'
        }
      },
      {
        path: "/roles/edit/:id",
        component: EditRole,
        meta: {
          requiresAuth: true,
          requiredPermission: 'edit_role'
        }
      },
    ],
  },
  {
    path: "/app-users",
    component: Body,
    children: [
      ...AppUserRoutes
    ],
  },
  {
    path: "/family-members",
    component: Body,
    children: [
      ...FamilyMemberRoutes
    ],
  },
  {
    path: "/qualifications",
    component: Body,
    children: [
      ...QualificationRoutes
    ],
  },
  {
    path: "/specializations",
    component: Body,
    children: [
      ...SpecializationRoutes
    ],
  },
  {
    path: "/doctors",
    component: Body,
    children: [
      ...DoctorRoutes
    ],
  },
  {
    path: "/chw",
    component: Body,
    children: [
      ...CHWRoutes
    ],
  },
  {
    path: "/tasks",
    component: Body,
    children: [
      ...TaskRoutes
    ],
  },
  {
    path: "/campaigns",
    component: Body,
    children: [
      ...CampaignRoutes
    ],
  },
  {
    path: "/health-literacy-content",
    component: Body,
    children: [
      ...healthLiteracyContentRoutes
    ],
  },
  {
    path: "/categories",
    component: Body,
    children: [
      ...categoryRoutes
    ],
  },
  {
    path: "/interests",
    component: Body,
    children: [
      ...interestRoutes
    ],
  },
  {
    path: "/user-messages",
    component: Body,
    children: [
      ...userMessaggesRoutes
    ],
  },
  {
    path: "/appointment-requests",
    component: Body,
    children: [
      {
        path: "/appointment-requests",
        component: IndexUserRequest,
        meta: {
          requiresAuth: true,
          requiredPermission: 'view_sub_admin'
        }
      },
    ],
  },
  {
    path: "/supports",
    component: Body,
    children: [
      ...supportRoutes
    ],
  },
  {
    path: "/feedbacks",
    component: Body,
    children: [
      ...feedbackRoutes
    ],
  },
  {
    path: "/dynamic-forms",
    component: Body,
    children: [
      ...DynamicFormRoutes
    ],
  },
  {
    path: "/facility-centers",
    component: Body,
    children: [
      ...facilityCenterRoutes
    ],
  },
  {
    path: "/pages",
    component: Body,
    children: [
      ...PagesRoutes
    ],
  },
  {
    path: "/faqs",
    component: Body,
    children: [
      ...faqRoutes
    ],
  },
  {
    path: "/medical-histories",
    component: Body,
    children: [
      ...MedicalHistoryRoutes
    ],
  },
  {
    path: "/prescriptions",
    component: Body,
    children: [
      ...PrescriptionRoutes
    ],
  },
  {
    path: "/appointments",
    component: Body,
    children: [
      ...AppointmentRoutes
    ],
  },
  {
    path: "/notifications",
    component: Body,
    children: [
      ...NotificationRoutes
    ],
  },
  {
    path: "/districts",
    component: Body,
    children: [
      {
        path: "/districts",
        component: IndexDistrict,
        meta: {
          requiresAuth: true,
          requiredPermission: 'view_location'
        }
      },
      {
        path: "/districts/add",
        component: AddDistrict,
        meta: {
          requiresAuth: true,
          requiredPermission: 'create_location'
        }
      },
      {
        path: "/districts/edit/:id",
        component: EditDistrict,
        meta: {
          requiresAuth: true,
          requiredPermission: 'edit_location'
        }
      },
    ],
  },
  {
    path: "/tehsils",
    component: Body,
    children: [
      {
        path: "/tehsils",
        component: IndexTehsil,
        meta: {
          requiresAuth: true,
          requiredPermission: 'view_location'
        }
      },
      {
        path: "/tehsils/add",
        component: AddTehsil,
        meta: {
          requiresAuth: true,
          requiredPermission: 'create_location'
        }
      },
      {
        path: "/tehsils/edit/:id",
        component: EditTehsil,
        meta: {
          requiresAuth: true,
          requiredPermission: 'edit_location'
        }
      },
    ],
  },
  {
    path: "/areas",
    component: Body,
    children: [
      {
        path: "/areas",
        component: IndexVC,
        meta: {
          requiresAuth: true,
          requiredPermission: 'view_location'
        }
      },
      {
        path: "/areas/add",
        component: AddVC,
        meta: {
          requiresAuth: true,
          requiredPermission: 'create_location'
        }
      },
      {
        path: "/areas/edit/:id",
        component: EditVC,
        meta: {
          requiresAuth: true,
          requiredPermission: 'edit_location'
        }
      },
    ],
  },

];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem("token");

  if (!to.meta?.public && !isAuthenticated) {
    next({ name: "Login 1" });
    return; // Ensure to return after calling next() to prevent further navigation
  }

  if (!to.meta?.requiredPermission) {
    // No permission required, allow navigation
    next();
    return;
  }

  const requiredPermission = to.meta.requiredPermission;

  const permissions = JSON.parse(localStorage.getItem("permissions"));

  if (!permissions || !permissions.includes(requiredPermission)) {
    // User doesn't have the required permission, redirect to unAuthorized page
    next({ name: "unAuthorized" });
    return;
  }

  // User has the required permission, allow navigation
  next();
});

export default router;
