<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5 mx-auto">
        <Bread_crumbs />
        <router-link to="/qualifications" class="c-button cbg-primary mb-3">Back</router-link>
        <div class="card mt-4">
          <div class="card-header">
            <h4 class="card-title">Add Qualifications</h4>
          </div>
          <div class="card-body">
            <form @submit.prevent="submit" ref="addQualification" id="add-qualifications" class="needs-validation"
              novalidate>
              <div class="form-group mb-3">
                <span class="text-danger" v-if="this.errors">{{ this.errors.title }}</span>
                <input type="text" class="form-control form-control-sm" placeholder="Title *" required
                  v-model="qualification.title" />
                <div class="invalid-feedback text-danger">
                  Please provide a title.
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <button type="submit" class="c-button cbg-primary">
                    Submit
                  </button>
                  <button type="button" @click="this.$refs.addQualification.reset()"
                    class="ms-2 c-button cbg-secondary">
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import { toRaw } from "vue";
import helpers from "@/helpers/helpers";
export default {
  components: {
    Bread_crumbs,
  },
  mixins: [helpers],
  data() {
    return {
      qualification: {
        title: "",
      },
      loading: false,
    };
  },
  mounted() {
  },
  methods: {
    submit() {
      var form = document.getElementById("add-qualifications");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw(this.qualification);
        this.$store
          .dispatch("qualifications/store", { data })
          .then((res) => {
            this.loading = false;
            if (res.success == true) {
              this.$toast.show("Successfuly added.", {
                theme: "outline",
                position: "top",
                type: "success",
                duration: 5000,
              });
              this.qualification = {
                title: "",
              };
              this.$refs.addQualification.reset();
              this.errors = [];
            } else {
              this.errors = this.formatErrors(res.error);
              this.$toast.show("Please fill the required fields in correct format!", {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.show(e.error, {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
  },
};
</script>