<template>
  <div class="row calender-main m-1 py-4 px-2">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <form
      @submit.prevent="submit"
      id="dutyRoaster"
      ref="DutyRoaster"
      class="needs-validation"
      novalidate
    >
      <div class="row justify-content-between">
        <div
          class="col-lg-4 col-md-12 col-12 calender-scheduled border rounded mb-5 p-2"
        >
          <div class="small fw-semibold mb-4">Scheduled</div>
          <div v-for="(dutyRoaster, index) in dutyRoasters" :key="index">
            <div
              href="#"
              class="card-date-color text-center rounded-3 text-white py-2 mb-3"
            >
              {{ dutyRoaster.date }}
            </div>
            <div class="text-white d-flex flex-wrap mb-4">
              <div
                v-for="(appointment, index) in dutyRoaster.appointments"
                :key="index"
              >
                <div
                  v-if="
                    appointment.status == 'Available' ||
                    appointment.status == 'Cancelled'
                  "
                  class="avail-schedule fs-12 rounded d-flex align-items-center justify-content-center me-1 my-1"
                >
                  {{ appointment.s_time }}
                </div>
                <div
                  v-if="appointment.status == 'Booked'"
                  class="unavail-schedule fs-12 rounded d-flex align-items-center justify-content-center me-1 my-1"
                >
                  {{ appointment.s_time }}
                </div>
                <!-- <div
                  v-if="appointment.status == 'Cancelled'"
                  class="cancel-schedule fs-12 rounded d-flex align-items-center justify-content-center me-1 my-1"
                >
                  {{ appointment.s_time }}
                </div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12 mb-5">
          <div class="small fw-semibold mb-4">Set Calendar</div>
          <div class="row">
            <div class="col-6">
              <div class="form-group">
                <label for="" class="small">Time From</label>
                <input
                  class="form-control form-control-sm"
                  v-model="this.duty_roasters.s_time"
                  type="time"
                  required
                />
                <div class="invalid-feedback text-danger">
                  Please provide a time from.
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <label for="" class="small">Time To</label>
                <input
                  class="form-control form-control-sm"
                  v-model="this.duty_roasters.e_time"
                  type="time"
                  required
                />
                <div class="invalid-feedback text-danger">
                  Please provide a time to.
                </div>
              </div>
            </div>
          </div>
          <div class="small fw-semibold mb-1 mt-4">
            Sessions <span class="fw-normal">(minutes)</span>
          </div>
          <input
            type="number"
            v-model="this.duty_roasters.session"
            class="form-control form-control-sm"
            placeholder="Duration"
            :disabled="totalMinutes >= 10 ? false : true"
            min="10"
            required
          />
          <div class="invalid-feedback text-danger">
            Please provide a session time minimum 10 minutes.
          </div>
        </div>
        <div class="col-lg-4 col-md-6 col-12 ms-0">
          <div class="small fw-semibold mb-4">Select a Day</div>
          <CDatePicker
            :slotsDates="slotsDates"
            @selectedDates="getSelectedDates"
            :key="componentKey"
            :isDisplayDates="false"
          ></CDatePicker>
          <button type="submit" class="btn btn-success w-100 mt-3">
            Add Duty Roaster
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { toRaw } from "vue";
import CDatePicker from "./c-date-picker.vue";
import helpers from "@/helpers/helpers";
export default {
  components: {
    CDatePicker,
  },
  props: {
    doctor_id: String,
  },
  mixins: [helpers],
  data() {
    return {
      events: [], // Add your events data here
      minDate: new Date(),
      duty_roasters: {
        doctor_id: null,
        s_time: null,
        e_time: null,
        session: null,
        dutyRoasters: [],
        created_by: localStorage.getItem("uid"),
      },

      dutyRoasters: {},
      selectedDates: [],
      slotsDates: [],
      timeFrom: null,
      timeTo: null,
      sessionTime: null,
      componentKey: 0,
      loading: false,
    };
  },
  methods: {
    submit() {
      var form = document.getElementById("dutyRoaster");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.createScheduleSlots();
        this.loading = true;
        const data = toRaw(this.duty_roasters);
        this.$store
          .dispatch("dutyRoasters/store", { data })
          .then((res) => {
            this.loading = false;
            if(res.success == true){
              this.dutyRoasters = res.data;
            res.data.forEach((slot) => {
              let date = this.getISODate(slot.date);
              this.slotsDates.push(date);
            });
            this.componentKey++;
            this.$toast.show("Successfuly added.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.duty_roasters = {
              doctor_id: this.doctor_id,
              s_time: null,
              e_time: null,
              session: null,
              dutyRoasters: [],
              created_by: localStorage.getItem("uid"),
            }
            // this.$refs.addDoctor.reset();
            this.errors = [];
            }else{
              this.errors = this.formatErrors(res.error);
              this.$toast.show(Object.values(this.errors), {
                theme: "outline",
                position: "top",
                type: "error",
                duration: 10000,
              });
            }
          })
          .catch((e) => {
            this.loading = false;
            this.$toast.show("Something went wrong!", {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
    fetch(id) {
      this.loading = false;
      this.$store
        .dispatch("dutyRoasters/getSingle", { id })
        .then((response) => {
          this.dutyRoasters = response.data;
          response.data.forEach((slot) => {
            let date = this.getISODate(slot.date);
            this.slotsDates.push(date);
          });
          this.loading = false;
          this.componentKey++;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    getISODate(date) {
      date = new Date(date);
      const year = date.getUTCFullYear();
      const month = date.getUTCMonth();
      const day = date.getUTCDate();
      return new Date(Date.UTC(year, month, day + 1))
        .toISOString()
        .split("T")[0];
    },
    getSelectedDates(dates) {
      this.selectedDates = dates;
    },
    createScheduleSlots() {
      this.selectedDates.forEach((day, index) => {
        const slots = [];
        const startTime = new Date(day);
        const [fromHours, fromMinutes] = this.duty_roasters.s_time
          .split(":")
          .map(Number); // Parse time from string
        startTime.setHours(fromHours, fromMinutes, 0, 0); // Set start time
        const [toHours, toMinutes] = this.duty_roasters.e_time
          .split(":")
          .map(Number); // Parse time to string
        const endTime = new Date(day);
        endTime.setHours(toHours, toMinutes, 0, 0); // Set end time

        let currentTime = new Date(startTime); // Initialize current time with start time

        while (currentTime < endTime) {
          const endTime = this.addMinutes(
            currentTime,
            this.duty_roasters.session
          );
          const slot = {
            s_time: this.formatTime(currentTime),
            e_time: this.formatTime(endTime),
          };
          slots.push(slot);
          currentTime = endTime;
        }
        this.duty_roasters.dutyRoasters[index] = {
          date: this.formatDate(day),
          appointments: slots,
        };
      });
    },
    formatTime(time) {
      return time
        .toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
        .toUpperCase();
    },
    formatDate(day) {
      day = new Date(day);
      const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return (
        day.getDate().toString().padStart(2, "0") +
        " " +
        months[day.getMonth()] +
        " " +
        day.getFullYear()
      );
    },
    addMinutes(date, minutes) {
      return new Date(date.getTime() + minutes * 60000);
    },
    timeToMinutes(time) {
      const [hours, minutes] = time.split(':').map(Number);
      return hours * 60 + minutes;
    }
  },
  created() {
    const id = this.doctor_id;
    this.duty_roasters.doctor_id = id;
    this.fetch(id);
  },
  computed: {
    totalMinutes() {
      const s_time = this.duty_roasters.s_time;
      const e_time = this.duty_roasters.e_time;

      if (!s_time || !e_time) return 0;

      // Convert time from HH:MM format to minutes
      const startMinutes = this.timeToMinutes(s_time);
      const endMinutes = this.timeToMinutes(e_time);

      // Calculate the difference
      const minutesDifference = endMinutes - startMinutes;

      return minutesDifference > 0 ? minutesDifference : 0; // Return positive difference or 0
    }
  },
};
</script>


<style>
.calender-scheduled {
  height: 400px;
  overflow-y: auto;
}
.avail-schedule {
  background: rgba(108, 220, 55, 1);
  padding: 8px;
  width: 85px;
}

.unavail-schedule {
  background: rgba(234, 64, 64, 1);
  padding: 8px;
}

.cancel-schedule {
  background: rgba(234, 64, 64, 1);
  padding: 8px;
}

.set-time input[type="time"] {
  width: 162px;
  height: 42px;
  background: rgba(244, 248, 249, 1);
  border: 1px solid rgba(228, 237, 240, 1);
  padding-inline: 10px;
  outline: none;
}

.set-time input[type="time"]:focus {
  border: 1px solid rgba(53, 53, 205, 1) !important;
}

.set-time .edit-profile-input {
  height: 42px;
  width: 335px;
}
.card-date-color {
  background-color: rgba(0, 0, 128, 1);
  width: 101px;
  height: 34px;
}

.card2-date-color {
  background: rgba(181, 181, 181, 1);
  border: transparent;
  width: 101px;
  height: 34px;
}

.card-font-color {
  height: 355px;
  color: rgba(51, 51, 51, 1) !important;
}
.home-main-container {
  max-width: 1302px;
  width: 100%;
  top: 0;
}

.home-main-container .card {
  border-radius: 16px;
}

.home-main-container .card-body {
  padding: 31px;
}
.vc-pane .vc-header {
  background: rgba(0, 0, 128, 1) !important;
  margin-top: 0 !important;
  height: 52px !important;
  border-radius: 8px 8px 0 0;
}

.vc-header .vc-title {
  background: rgba(0, 0, 128, 1);
  color: white !important;
  font-size: 16px;
}

.vc-arrow {
  background-color: rgba(0, 0, 128, 1);
}

polyline {
  color: white;
}

.vc-weekdays {
  border-bottom: 1px solid rgba(237, 237, 237, 1);
}

.calender-day {
  height: 32px;
  width: 32px;
  border: 1px solid rgba(0, 0, 128, 0.16);
  border-radius: 31px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: rgba(0, 0, 128, 1);
  margin-right: 3px;
  cursor: pointer;
}

.login-inputs {
  position: relative;
}

.color-purple {
  color: rgba(0, 0, 128, 1);
}

.card-vline {
  height: 76px;
  width: 1px;
  background-color: rgba(217, 217, 217, 1);
}

.Active {
  background-color: rgba(0, 0, 128, 1);
  color: white !important;
}
@media only screen and (min-width: 1200px) and (max-width: 1400px) {
  .head-row {
    max-width: 1116px;
  }

  .image .img {
    width: 230px;
    height: 130px;
  }

  .opacity-img {
    width: 230px;
    height: 130px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .head-row {
    max-width: 936px;
  }

  header .head-row .search > button {
    width: 35px;
    height: 35px;
  }

  header .head-row .notification button {
    width: 35px;
    height: 35px;
  }

  .search button img {
    width: 14px;
    height: 14px;
  }

  .notification button img {
    width: 16px;
    height: 19px;
  }

  header .head-row .notification button span {
    width: 5px;
    height: 5px;
    right: 10px;
    top: 10px;
  }

  .doctor-header-pic {
    width: 35px;
    height: 35px;
  }

  .card-date-color {
    width: 90px !important;
    height: 34px !important;
    font-size: 14px !important;
  }

  .card2-date-color {
    width: 90px !important;
    height: 34px !important;
    font-size: 14px !important;
  }

  .card-body .fs-12 {
    font-size: 10px;
  }

  .home-main-container .card-body {
    padding: 25px 25px 15px 25px;
  }

  .card-font-color {
    height: 305px;
  }

  .home-card-main .card-body img {
    height: 74px;
    width: 74px;
  }

  .dash-search-input {
    width: 100%;
  }

  .fs-6 {
    font-size: 14px !important;
  }

  .btn-success {
    font-size: 14px;
  }

  .card h5 {
    font-size: 18px;
  }

  .image .img {
    width: 200px;
    height: 130px;
  }

  .opacity-img {
    width: 200px;
    height: 130px;
  }

  .home-videos a {
    font-size: 14px;
  }

  .patient img {
    width: 64px;
    height: 64px;
  }

  .patient h6 {
    font-size: 14px;
  }

  .patient-profile-disease {
    width: 235px;
    height: 198px;
  }

  .patient-profile img {
    width: 90px;
    height: 90px;
  }

  .patient-profile .fs-14,
  .patient-profile small {
    font-size: 12px;
  }

  .survey-main-div {
    padding-inline: 48px;
  }

  .support-links-main-container {
    padding-inline: 58px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  header {
    font-size: 12px;
  }

  .head-row {
    max-width: 936px;
  }

  header .head-row .search > button {
    width: 35px;
    height: 35px;
  }

  header .head-row .notification button {
    width: 35px;
    height: 35px;
  }

  .search button img {
    width: 14px;
    height: 14px;
  }

  .modal-input-logo {
    margin-right: 12px;
    top: 23px;
  }

  .dash-search-input {
    width: 100%;
    height: 70px;
    font-size: 18px;
  }

  .login-inputs img {
    width: 25px;
    height: 25px;
  }

  .notification button img {
    width: 16px;
    height: 19px;
  }

  header .head-row .notification button span {
    width: 5px;
    height: 5px;
    right: 10px;
    top: 10px;
  }

  .doctor-header-pic {
    width: 35px;
    height: 35px;
  }

  .container {
    max-width: 936px;
  }

  .card-date-color {
    width: 90px;
    height: 34px;
    font-size: 12px;
  }

  .home-main-container .card-body {
    padding: 25px 25px 15px 25px;
  }

  .card-font-color {
    height: 305px;
  }

  .home-card-main .card-body img {
    height: 70px;
    width: 70px;
  }

  .card2-date-color {
    width: 90px;
    height: 34px;
    font-size: 12px;
  }

  .card-body .fs-12 {
    font-size: 10px;
    text-wrap: nowrap;
  }

  .fs-6 {
    font-size: 12px !important;
  }

  .btn-success {
    font-size: 12px;
  }

  .card h5 {
    font-size: 18px;
  }

  .image .img {
    width: 170px;
    height: 115px;
  }

  .opacity-img {
    width: 170px;
    height: 115px;
  }

  .home-videos a {
    font-size: 14px;
  }

  .patient img {
    width: 54px;
    height: 54px;
  }

  .patient h6 {
    font-size: 13px;
  }

  .card-vline {
    height: 56px;
  }

  .patient-profile-disease {
    margin-left: 48px;
    margin-top: 20px;
    width: 100%;
    height: 130px;
  }

  .patient-profile img {
    width: 120px;
    height: 120px;
  }

  .patient-profile .fs-14,
  .patient-profile small {
    font-size: 12px;
  }

  .add-prescription-main textarea {
    padding-top: 5px;
    font-size: 14px;
  }

  .family-profile-main-div {
    overflow-x: auto;
    font-size: 12px;
  }

  .survey-main-div {
    padding-inline: 48px;
  }

  .modal-prescription-note {
    width: 100%;
  }

  .patient-profile-disease {
    font-size: 14px;
  }

  .support-links-main-container {
    padding-inline: 58px;
  }

  .accordion-button {
    font-size: 18px;
  }

  .accordion-body {
    font-size: 14px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
  .head-row {
    max-width: 936px;
  }

  .container {
    max-width: 936px;
  }

  header .head-row .search > button {
    width: 35px;
    height: 35px;
  }

  header .head-row .notification button {
    width: 35px;
    height: 35px;
  }

  .search button img {
    width: 14px;
    height: 14px;
  }

  .notification button img {
    width: 16px;
    height: 19px;
  }

  header .head-row .notification button span {
    width: 5px;
    height: 5px;
    right: 10px;
    top: 10px;
  }

  .doctor-header-pic {
    width: 35px;
    height: 35px;
  }
}
</style>