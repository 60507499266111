import axios from "@/helpers/axios";
export default {
  methods: {
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    formatErrors(errors) {
      if (!this.isObject(errors)) {
        return errors;
      }
      const formatted = {};
      for (const [field, messages] of Object.entries(errors)) {
        // Join messages into a single string
        formatted[field] = messages.join(', ');
      }
      return formatted;
    },
    isObject(value) {
      return value && typeof value === 'object' && !Array.isArray(value);
    },
    async uploadFileInChunks(updateCallBack, file, folder, chunkSize) {
      chunkSize = chunkSize * 1024 * 1024; // 5 MB chunks
      const totalChunks = Math.ceil(file.size / chunkSize);
      const timestamp = Math.floor(Date.now() / 1000);

      const fileExtension = file.name.split('.').pop();

      // this.fileUploadingPercentage = "0.01";

      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = Math.min(start + chunkSize, file.size);
        const chunk = file.slice(start, end);

        const formData = new FormData();
        formData.append('chunk', chunk);
        formData.append('index', i + 1);
        formData.append('totalChunks', totalChunks);
        formData.append('timestamp', timestamp);
        formData.append('fileExtension', fileExtension);
        formData.append('folder', folder);

        const response = await axios.post('/media-file/upload-chunk', formData);

        if (response.data.success) {
          updateCallBack({
            success: true,
            message: response.data.message,
            percentage: (((i + 1) / totalChunks) * 100).toFixed(2),
            file: { path: response.data.file.path, mimeType: file.type }
          })
        } else {
          updateCallBack({
            success: false,
            message: response.data.message,
            percentage: 0,
            file: null
          })
          return;
        }

      }
    },
  },
};
