// views list for CHWs here
import ListCHW from "@/views/chw/index";
import AddCHW from "@/views/chw/add";
import EditCHW from "@/views/chw/edit";
import CHWView from "@/views/chw/show";

const routes = [
  {
    path: '/chw/',
    name: 'CHW',
    component: ListCHW,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_chw'
    },
    props: true
  },
  {
    path: '/chw/add',
    name: 'Add CHW',
    component: AddCHW,
    meta: {
      requiresAuth: true,
      requiredPermission: 'create_chw'
    }
  },
  {
    path: '/chw/edit/:id',
    name: 'Edit CHW',
    component: EditCHW,
    meta: {
      requiresAuth: true,
      requiredPermission: 'edit_chw'
    },
    props: true
  },

  {
    path: '/chw/show/:id',
    name: 'CHW Detail',
    component: CHWView,
    meta: {
      requiresAuth: true,
      requiredPermission: 'view_chw'
    },
    props: true
  },
];

export default routes;
