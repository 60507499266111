<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="col-md-12 mt-5">
        <Bread_crumbs />
        <!-- <router-link
          to="/user-messages/add"
          class="c-button cbg-primary mb-2 float-end"
          >Add User Messages</router-link
        > -->
      </div>
      <div class="col-md-12">
        <data-table
          :columns="columns"
          :module="module"
          :items="items"
          :isAction="false"
          @nextPage="nextPage"
          @search="fetch"
          @clear="fetch"
        ></data-table>
      </div>
    </div>
  </div>
</template>
  <script>
import Bread_crumbs from "@/components/bread_crumbs.vue";
import DataTable from "@/components/dataTable.vue";
import axiosInstance from "@/helpers/axios";

export default {
  components: {
    DataTable,
    Bread_crumbs,
  },
  data() {
    return {
      items: [],
      columns: [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Patient",
          value: "patient",
          secondValue:"f_name",
        },
        {
          text: "Description",
          value: "detail",
        },
        {
          text: "Emergency",
          value: "is_emergency",
          // secondValue:"status",
        },
        {
          text: "Dated",
          value: "dated_at",
          // secondValue:"status",
        },
      ],
      module: "appointmentRequests",
      loading: false,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    fetch(search = "") {
      console.log("fetched successfully");
      this.loading = true;
      this.$store
        .dispatch("appointmentRequests/getAll", { search, paginate: true })
        .then((response) => {
          this.items = response.data;
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          console.log(e.error);
        });
    },
    nextPage(url) {
      this.loading = true;
      axiosInstance.get(url).then((response) => {
        this.items = response.data.data;
        this.loading = false;
      });
    },
  },
};
</script>
  