<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="row">
        <div class="col-md-12">
          <form
            @submit.prevent="submit"
            id="banner"
            ref="addBanner"
            class="needs-validation"
            novalidate
          >
            <div class="form-group row">
              <div class="col-md-12 mb-3">
                <span class="text-danger d-block" v-if="errors.desc">
                  {{ errors.desc }}
                </span>
                <textarea
                  name=""
                  id=""
                  rows="7"
                  class="text-area"
                  placeholder="Type description.... *"
                  v-model="banner.desc"
                  required
                ></textarea>
                <div class="invalid-feedback text-danger">
                  Please provide a description.
                </div>
              </div>
              <div class="col-md-6">
                <img
                  v-if="backgroundImagePreview"
                  :src="backgroundImagePreview"
                  alt=""
                  class="my-2"
                  height="50"
                />

                <label for="file-upload-background" class="custom-file-upload form-control"
                  >Upload Background File *</label
                >
                <span class="text-danger d-block" v-if="errors.file">
                  {{ errors.file }}
                </span>
                <input
                  type="file"
                  ref="file_background"
                  id="file-upload-background"
                  accept="image/*"
                  placeholder="Select File *"
                  class="form-control form-control-sm"
                  @change="selectFile('backgroundImagePreview')"
                  
                />
                <div class="invalid-feedback text-danger">
                  Please select a file.
                </div>
              </div>
              <div class="col-md-6">
                <img
                  v-if="centerImagePreview"
                  :src="centerImagePreview"
                  alt=""
                  class="my-2"
                  height="50"
                />

                <label for="file-upload-center" class="custom-file-upload form-control"
                  >Upload Center File *</label
                >
                <span class="text-danger d-block" v-if="errors.file">
                  {{ errors.file }}
                </span>
                <input
                  type="file"
                  ref="file_center"
                  id="file-upload-center"
                  accept="image/*"
                  placeholder="Select File *"
                  class="form-control form-control-sm"
                  @change="selectFile('centerImagePreview')"
                  
                />
                <div class="invalid-feedback text-danger">
                  Please select a file.
                </div>
              </div>
              <div class="col-md-6">
                <img
                  v-if="personImagePreview"
                  :src="personImagePreview"
                  alt=""
                  class="my-2"
                  height="50"
                />

                <label for="file-upload-person" class="custom-file-upload form-control"
                  >Upload Person File *</label
                >
                <span class="text-danger d-block" v-if="errors.file">
                  {{ errors.file }}
                </span>
                <input
                  type="file"
                  ref="file_person"
                  id="file-upload-person"
                  accept="image/*"
                  placeholder="Select File *"
                  class="form-control form-control-sm"
                  @change="selectFile('personImagePreview')"
                  
                />
                <div class="invalid-feedback text-danger">
                  Please select a file.
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-3">
                <button type="submit" class="c-button cbg-primary">
                  Submit
                </button>
                <button
                  type="button"
                  @click="this.$refs.addBanner.reset()"
                  class="ms-2 c-button cbg-secondary"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";
import Bread_crumbs from "@/components/bread_crumbs.vue";

export default {
  components: {
    Bread_crumbs,
  },
  props: {
    bannerData: Object,
  },
  mixins: [isNumber],
  data() {
    return {
      banner: {
        desc:null,
        background_image: null,
        center_image: null,
        person_image: null,
      },
      backgroundImagePreview: "/promotionals/banner/background_image.png",
      centerImagePreview: "/promotionals/banner/center_image.png",
      personImagePreview: "/promotionals/banner/person_image.png",
      errors: [],
      loading: false,
    };
  },
  mounted() {
    if(this.bannerData != "{}"){
      this.banner = this.bannerData;
      this.backgroundImagePreview = this.bannerData.background.path;
      this.centerImagePreview = this.bannerData.center.path;
      this.personImagePreview = this.bannerData.person.path;
    }
  },
  methods: {
    submit() {
      var form = document.getElementById("banner");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw(this.banner);
        this.$store
          .dispatch("promotional/storeBanner", { data })
          .then((res) => {
            this.loading = false;
            this.$toast.show("Successfuly added.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.$refs.addBanner.reset();
            this.errors = [];
          })
          .catch((e) => {
            this.loading = false;
            this.errors = e.error;
            this.$toast.show(JSON.stringify(e.error), {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
    selectFile(imagePreview) {
      let file;
      if (imagePreview == "backgroundImagePreview") {
        file = this.$refs.file_background.files[0];
        } else if (imagePreview == "centerImagePreview") {
          file = this.$refs.file_center.files[0];
        } else if (imagePreview == "personImagePreview") {
          file = this.$refs.file_person.files[0];
        }
      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png"
      ) {
        if (
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png"
        ) {
          this.attachmentType = "Image";
        }
        let reader = new FileReader();
        reader.onloadend = () => {
          if (imagePreview == "backgroundImagePreview") {
            this.banner.background_image = reader.result;
            this.backgroundImagePreview = reader.result;
          } else if (imagePreview == "centerImagePreview") {
            this.banner.center_image = reader.result;
            this.centerImagePreview = reader.result;
          } else if (imagePreview == "personImagePreview") {
            this.banner.person_image = reader.result;
            this.personImagePreview = reader.result;
          }
        };
        reader.readAsDataURL(file);
      } else {
        file.value = null;
        this.$toast.show("File format not supported. JPG/JPEG/PNG", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
  },
};
</script>
  