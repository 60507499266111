<template>
  <div class="container">
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <div class="row">
      <div class="row">
        <div class="col-md-12">
          <form
            @submit.prevent="submit"
            id="about"
            ref="addAbout"
            class="needs-validation"
            novalidate
          >
            <h4 class="underline">Section 1</h4>
            <div class="form-group row">
              <div class="col-md-12">
                <h6>Box 1</h6>
                <div class="row">
                  <div class="col-md-8">
                    <div class="col-md-12 mb-3">
                      <input
                        type="text"
                        placeholder="Title *"
                        class="form-control form-control-sm"
                        v-model="about.box_1_title"
                      />
                    </div>
                    <div class="col-md-12 mb-3">
                      <input
                        type="text"
                        placeholder="Description *"
                        class="form-control form-control-sm"
                        v-model="about.box_1_desc"
                      />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label
                        for="file-upload-box1"
                        class="custom-file-upload form-control"
                        >Upload Box 1 File *</label
                      >
                      <input
                        type="file"
                        ref="box1"
                        id="file-upload-box1"
                        accept="image/*"
                        placeholder="Select File *"
                        class="form-control form-control-sm"
                        @change="selectFile('box1')"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <img
                      v-if="box1ImagePreview"
                      :src="box1ImagePreview"
                      alt=""
                      class="w-75"
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div class="col-md-12">
                <h6>Box 2</h6>
                <div class="row">
                  <div class="col-md-8">
                    <div class="col-md-12 mb-3">
                      <input
                        type="text"
                        placeholder="Title *"
                        class="form-control form-control-sm"
                        v-model="about.box_2_title"
                      />
                    </div>
                    <div class="col-md-12 mb-3">
                      <input
                        type="text"
                        placeholder="Description *"
                        class="form-control form-control-sm"
                        v-model="about.box_2_desc"
                      />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label
                        for="file-upload-box2"
                        class="custom-file-upload form-control"
                        >Upload Box 2 File *</label
                      >
                      <input
                        type="file"
                        ref="box2"
                        id="file-upload-box2"
                        accept="image/*"
                        placeholder="Select File *"
                        class="form-control form-control-sm"
                        @change="selectFile('box2')"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <img
                      v-if="box2ImagePreview"
                      :src="box2ImagePreview"
                      alt=""
                      class="w-75"
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div class="col-md-12">
                <h6>Box 3</h6>
                <div class="row">
                  <div class="col-md-8">
                    <div class="col-md-12 mb-3">
                      <input
                        type="text"
                        placeholder="Title *"
                        class="form-control form-control-sm"
                        v-model="about.box_3_title"
                      />
                    </div>
                    <div class="col-md-12 mb-3">
                      <input
                        type="text"
                        placeholder="Description *"
                        class="form-control form-control-sm"
                        v-model="about.box_3_desc"
                      />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label
                        for="file-upload-box3"
                        class="custom-file-upload form-control"
                        >Upload Box 3 File *</label
                      >
                      <input
                        type="file"
                        ref="box3"
                        id="file-upload-box3"
                        accept="image/*"
                        placeholder="Select File *"
                        class="form-control form-control-sm"
                        @change="selectFile('box3')"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <img
                      v-if="box3ImagePreview"
                      :src="box3ImagePreview"
                      alt=""
                      class="w-75"
                    />
                  </div>
                </div>
              </div>
              <hr />
              <div class="col-md-12">
                <h6>Box 4</h6>
                <div class="row">
                  <div class="col-md-8">
                    <div class="col-md-12 mb-3">
                      <input
                        type="text"
                        placeholder="Title *"
                        class="form-control form-control-sm"
                        v-model="about.box_4_title"
                      />
                    </div>
                    <div class="col-md-12 mb-3">
                      <input
                        type="text"
                        placeholder="Description *"
                        class="form-control form-control-sm"
                        v-model="about.box_4_desc"
                      />
                    </div>
                    <div class="col-md-12 mb-3">
                      <label
                        for="file-upload-box4"
                        class="custom-file-upload form-control"
                        >Upload Box 4 File *</label
                      >
                      <input
                        type="file"
                        ref="box4"
                        id="file-upload-box4"
                        accept="image/*"
                        placeholder="Select File *"
                        class="form-control form-control-sm"
                        @change="selectFile('box4')"
                      />
                    </div>
                  </div>
                  <div class="col-md-4">
                    <img
                      v-if="box4ImagePreview"
                      :src="box4ImagePreview"
                      alt=""
                      class="w-75"
                    />
                  </div>
                </div>
              </div>
              <hr />
            </div>
            <h4 class="underline">Section 2</h4>
            <div class="form-group row">
              <div class="col-md-8">
                <div class="col-md-12 mb-3">
                  <input
                    type="text"
                    placeholder="Title *"
                    class="form-control form-control-sm"
                    v-model="about.title"
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <input
                    type="text"
                    placeholder="Heading *"
                    class="form-control form-control-sm"
                    v-model="about.heading"
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <textarea
                    name=""
                    id=""
                    rows="3"
                    class="text-area"
                    placeholder="Type description.... *"
                    v-model="about.desc"
                  ></textarea>
                </div>
              </div>
              <div class="col-md-4">
                <img :src="aboutImageUrl" class="w-100" height="180" alt="" />
              </div>
            </div>
            <div class="form-group row">
              <h6>Box 1</h6>
              <div class="col-md-8">
                <div class="col-md-12 mb-3">
                  <input
                    type="text"
                    placeholder="Value *"
                    class="form-control form-control-sm"
                    v-model="about.box_analytic_1_value"
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <input
                    type="text"
                    placeholder="Key *"
                    class="form-control form-control-sm"
                    v-model="about.box_analytic_1_key"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <img :src="boxAnalytic1ImagePreview" class="w-75" alt="" />
              </div>
            </div>
            <div class="form-group row">
              <h6>Box 2</h6>
              <div class="col-md-8">
                <div class="col-md-12 mb-3">
                  <input
                    type="text"
                    placeholder="Value *"
                    class="form-control form-control-sm"
                    v-model="about.box_analytic_2_value"
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <input
                    type="text"
                    placeholder="Key *"
                    class="form-control form-control-sm"
                    v-model="about.box_analytic_2_key"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <img :src="boxAnalytic2ImagePreview" class="w-75" alt="" />
              </div>
            </div>
            <div class="form-group row">
              <h6>Box 3</h6>
              <div class="col-md-8">
                <div class="col-md-12 mb-3">
                  <input
                    type="text"
                    placeholder="Value *"
                    class="form-control form-control-sm"
                    v-model="about.box_analytic_3_value"
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <input
                    type="text"
                    placeholder="Key *"
                    class="form-control form-control-sm"
                    v-model="about.box_analytic_3_key"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <img :src="boxAnalytic3ImagePreview" class="w-75" alt="" />
              </div>
            </div>
            <div class="form-group row">
              <h6>Box 4</h6>
              <div class="col-md-8">
                <div class="col-md-12 mb-3">
                  <input
                    type="text"
                    placeholder="Value *"
                    class="form-control form-control-sm"
                    v-model="about.box_analytic_4_value"
                  />
                </div>
                <div class="col-md-12 mb-3">
                  <input
                    type="text"
                    placeholder="Key *"
                    class="form-control form-control-sm"
                    v-model="about.box_analytic_4_key"
                  />
                </div>
              </div>
              <div class="col-md-4">
                <img :src="boxAnalytic4ImagePreview" class="w-75" alt="" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 mt-3">
                <button type="submit" class="c-button cbg-primary">
                  Submit
                </button>
                <button
                  type="button"
                  @click="this.$refs.addAbout.reset()"
                  class="ms-2 c-button cbg-secondary"
                >
                  Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { toRaw } from "vue";
import isNumber from "@/helpers/helpers";
import Bread_crumbs from "@/components/bread_crumbs.vue";

export default {
  components: {
    Bread_crumbs,
  },
  props: {
    aboutData: Object,
  },
  mixins: [isNumber],
  data() {
    return {
      about: {
        title: null,
        heading: null,
        desc: null,
        box_1_title: null,
        box_1_desc: null,
        box_1_file: null,
        box_2_title: null,
        box_2_desc: null,
        box_2_file: null,
        box_3_title: null,
        box_3_desc: null,
        box_3_file: null,
        box_4_title: null,
        box_4_desc: null,
        box_4_file: null,
        box_analytic_1_value: null,
        box_analytic_1_key: null,
        box_analytic_2_value: null,
        box_analytic_2_key: null,
        box_analytic_3_value: null,
        box_analytic_3_key: null,
        box_analytic_4_value: null,
        box_analytic_4_key: null,
      },
      aboutImageUrl: "/promotionals/about/about-detail.png",
      box1ImagePreview: "/promotionals/about/box-1.png",
      box2ImagePreview: "/promotionals/about/box-2.png",
      box3ImagePreview: "/promotionals/about/box-3.png",
      box4ImagePreview: "/promotionals/about/box-4.png",
      boxAnalytic1ImagePreview: "/promotionals/analytics/box-1.png",
      boxAnalytic2ImagePreview: "/promotionals/analytics/box-2.png",
      boxAnalytic3ImagePreview: "/promotionals/analytics/box-3.png",
      boxAnalytic4ImagePreview: "/promotionals/analytics/box-4.png",
      errors: [],
      loading: false,
    };
  },
  mounted() {
    if (this.aboutData != "{}") {
      this.about = this.aboutData;
      this.box1ImagePreview = this.aboutData.box_1.path;
      this.box2ImagePreview = this.aboutData.box_2.path;
      this.box3ImagePreview = this.aboutData.box_3.path;
      this.box4ImagePreview = this.aboutData.box_4.path;
    }
  },
  methods: {
    submit() {
      var form = document.getElementById("about");
      if (form.checkValidity() === false) {
        form.classList.add("was-validated");
      } else {
        this.loading = true;
        const data = toRaw(this.about);
        this.$store
          .dispatch("promotional/storeAbout", { data })
          .then((res) => {
            this.loading = false;
            this.$toast.show("Successfuly added.", {
              theme: "outline",
              position: "top",
              type: "success",
              duration: 5000,
            });
            this.$refs.addAbout.reset();
            this.errors = [];
          })
          .catch((e) => {
            this.loading = false;
            this.errors = e.error;
            this.$toast.show(JSON.stringify(e.error), {
              theme: "outline",
              position: "top",
              type: "error",
              duration: 10000,
            });
          });
      }
    },
    selectFile(imagePreview) {
      let file;
      if (imagePreview == "box1") {
        file = this.$refs.box1.files[0];
      } else if (imagePreview == "box2") {
        file = this.$refs.box2.files[0];
      } else if (imagePreview == "box3") {
        file = this.$refs.box3.files[0];
      } else if (imagePreview == "box4") {
        file = this.$refs.box4.files[0];
      }
      if (
        file.type == "image/jpg" ||
        file.type == "image/jpeg" ||
        file.type == "image/png"
      ) {
        if (
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/png"
        ) {
          this.attachmentType = "Image";
        }
        let reader = new FileReader();
        reader.onloadend = () => {
          if (imagePreview == "box1") {
            this.about.box_1_file = reader.result;
            this.box1ImagePreview = reader.result;
          } else if (imagePreview == "box2") {
            this.about.box_2_file = reader.result;
            this.box2ImagePreview = reader.result;
          } else if (imagePreview == "box3") {
            this.about.box_3_file = reader.result;
            this.box3ImagePreview = reader.result;
          } else if (imagePreview == "box4") {
            this.about.box_4_file = reader.result;
            this.box4ImagePreview = reader.result;
          }
        };
        reader.readAsDataURL(file);
      } else {
        file.value = null;
        this.$toast.show("File format not supported. JPG/JPEG/PNG", {
          theme: "outline",
          position: "top",
          type: "error",
          duration: 5000,
        });
      }
    },
  },
};
</script>
  