<template>
  <div>
    <loading :active="loading" :is-full-page="true" loader="bars"></loading>
    <!-- page-wrapper Start-->
    <div class="c-top-elipse"></div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 p-0">
          <div class="login-card">
            <div>
              <div>
              </div>
              <div class="login-main">
                <form class="theme-form" @submit.prevent="login">
                  <a class="logo">
                    <img
                      class="img-fluid"
                      src="@/assets/vhw-1.png"
                      alt="looginpage"
                      width="100"
                    />
                  </a>
                  <h4 class="text-center mb-3">Login</h4>
                  <div class="d-flex justify-content-center">
                    <!-- <p class="w-75 text-center opacity-0">You can search course, apply course and find
                      scholarship for abroad studies</p> -->
                  </div>
                  <span class="text-danger" v-if="errors.login">{{
                    errors.login
                  }}</span>
                  <div class="form-group">
                    <!-- <label class="col-form-label">Email Address</label> -->
                    <input
                      class="c-form-control"
                      type="email"
                      placeholder="email@test.com"
                      v-model="user.email"
                      required
                      autofocus
                    />
                    <!-- <span class="c-icon">
                      <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.24541 11C5.05696 11 4.86852 10.9244 4.72495 10.7739L0.215947 6.04581C-0.0719824 5.74405 -0.0719824 5.25613 0.215947 4.95437C0.503742 4.6526 0.968931 4.6526 1.25686 4.95437L5.24541 9.13654L13.7432 0.226324C14.031 -0.0754414 14.4962 -0.0754414 14.7842 0.226324C15.0719 0.528232 15.0719 1.016 14.7842 1.31791L5.766 10.7739C5.62244 10.9244 5.43386 11 5.24541 11Z" fill="#677294"/>
                        </svg>
                        
                    </span> -->
                    <span class="text-danger" v-if="errors.email">{{
                      errors.email[0]
                    }}</span>
                  </div>
                  <div class="form-group">
                    <!-- <label class="col-form-label">Password</label> -->
                    <div class="form-input position-relative">
                      <input
                        class="c-form-control "
                        :type="passwordType"
                        placeholder="*********"
                        v-model="user.password"
                        required
                      />
                      <span class="c-icon cursor" @click="togglePassword()">
                        <img src="../assets/svg/show-password.svg" v-if="passwordType == 'text'" alt=""> 
                        <img src="../assets/svg/hide-password.svg" v-if="passwordType == 'password'" alt=""> 
                      </span>
                      <span class="text-danger" v-if="errors.password">{{
                        errors.password[0]
                      }}</span>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <div class="checkbox p-0">
                      <input
                        id="checkbox1"
                        type="checkbox"
                        value="true"
                        class="c-checkbox"
                        v-model="user.remember_me"
                      />
                      <label class="text-muted" for="checkbox1"
                        >Remember me</label
                      >
                    </div>
                    <router-link class="link" to="/auth/verifyEmail"
                      >Forgot password?</router-link
                    >
                    <div class="text-end mt-3">
                      <button
                        class="c-btn w-100"
                        type="submit"
                      >
                        Sign in
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- latest jquery-->
  </div>
  <div class="c-bottom-elipse"></div>

</template>

<script>
import { nextTick, toRaw } from "vue";
import router from "@/router";
import '../assets/css/auth.css'

export default {
  name: "login",
  data() {
    return {
      user: {
        email: "",
        password: "",
        remember_me: false,
      },
      passwordType:'password',
      errors: [],
      loading: false,
      permissions: [],
    };
  },
  created() {
    if (!!localStorage.getItem("token")) {
      router.push({ path: "/" });
    }
    document.body.style.overflow = 'hidden';
  },
  methods: {
    login() {
      this.loading = true;
      this.errors = [];
      const data = toRaw(this.user);
      this.$store
        .dispatch("auth/login", { data })
        .then((response) => {
          if (response.success == true) {
    // this.$store.dispatch('fetchPermissions',['view_sub_admin']);

            // response.data.roles[0].permissions.forEach((item) => {
            //   this.permissions.push(item.name);
            // });
            console.log(response.data.roles);
            localStorage.setItem("permissions", JSON.stringify(response.data.permissions));
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("uid", response.data.encrypted_id);
            localStorage.setItem("pusher_id", response.data.id);
            localStorage.setItem("f_name", response.data.f_name);
            localStorage.setItem("profile_image", response.data.file.path);
            // localStorage.setItem("permissions", toRaw(this.permissions));
            localStorage.setItem(
              "remember_me",
              response.data.remember_me_token
            );
            this.loading = false;
            nextTick()
            window.location.href = "/dashboard";

          }
        })
        .catch((e) => {
          this.loading = false;
          this.errors = e.error;
        });
    },

    togglePassword(){
      if(this.passwordType == "password"){
        this.passwordType = "text";
      }else{
        this.passwordType = "password";
      }
    }
  },
};
</script>

<style scoped>


</style>
