import { createStore } from "vuex";

import layout from "./modules/layout";
import menu from "./modules/menu";
import auth from "./modules/auth";
import admins from "./modules/admins";
import users from "./modules/users";
import roles from "./modules/roles";
import districts from "./modules/districts";
import tehsils from "./modules/tehsils";
import areas from "./modules/areas";
import appUsers from "./modules/appUsers";
import familyMembers from "./modules/familyMembers";
import qualifications from "./modules/qualifications";
import specializations from "./modules/specializations";
import doctors from "./modules/doctors";
import dutyRoasters from "./modules/dutyRoasters";
import chw from "./modules/chw";
import campaigns from "./modules/campaigns";
import dynamicForms from "./modules/dynamicForms";
import tasks from "./modules/tasks";
import healthLiteracyContent from "./modules/healthLiteracyContent";
import facilityCenters from "./modules/facilityCenters";
import feedbacks from "./modules/feedbacks";
import supports from "./modules/supports";
import pages from "./modules/pages";
import faqs from "./modules/faqs";
import medicalHistories from "./modules/medicalHistories";
import prescriptions from "./modules/prescriptions";
import appointments from "./modules/appointments";
import notifications from "./modules/notifications";
import promotional from "./modules/promotional";
import dashboard from "./modules/dashboard";
import categories from "./modules/categories";
import interests from "./modules/interests";
import userMessages from "./modules/userMessages";
import appointmentRequests from "./modules/appointmentRequests";

export default createStore({
  state: { langIcon: "", langLangauge: "", isActive: false, permissions: [] },
  getters: {
    langIcon: (state) => {
      return state.langIcon;
    },
    langLangauge: (state) => {
      return state.langLangauge;
    },
    hasPermission: state => permission => {
      console.log('hasPermission: '+permission, state.permissions);
      return state.permissions.includes(permission);
    },
    getPermissions: (state)=>{
      return state.permissions;
    }
  },
  mutations: {
    changeLang(state, payload) {
      localStorage.setItem("currentLanguage", payload.id);
      localStorage.setItem("currentLanguageIcon", payload.icon);
      state.langIcon = payload.icon || "flag-icon-us";
      state.langLangauge = payload.id || "EN";
    },
    change(state) {
      state.isActive = !state.isActive;
    },
    setPermissions(state, permissions) {
      console.log('setPermissions', permissions);
      state.permissions = permissions;
    },
  },
  actions: {
    setLang({ commit }, payload) {
      commit("changeLang", payload);
    },
    fetchPermissions({ commit },permissions) {
      console.log(permissions);
      // state.permissions = permissions;
      commit('setPermissions', permissions);
    }
  },
  modules: {
    layout,
    menu,
    auth,
    dashboard,
    admins,
    users,
    roles,
    districts,
    tehsils,
    areas,
    appUsers,
    familyMembers,
    qualifications,
    specializations,
    doctors,
    dutyRoasters,
    chw,
    campaigns,
    dynamicForms,
    tasks,
    healthLiteracyContent,
    facilityCenters,
    feedbacks,
    supports,
    pages,
    faqs,
    medicalHistories,
    prescriptions,
    appointments,
    notifications,
    promotional,
    categories,
    interests,
    userMessages,
    appointmentRequests,
  },
});
